import { defineMessages, useIntl } from 'gatsby-plugin-locale';
import React from 'react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/seo';
import { Link } from '../components/Link';

const messages = defineMessages({
	pageDoesnotExist: {
		id: 'four04.pageDoesnotExist',
		defaultMessage: 'Deze pagina bestaan niet.',
	},
	sub: {
		id: 'four04.sub',
		defaultMessage: 'Je kan best teruggaan naar de home pagina.',
	},
	goBack: {
		id: 'four04.goBack',
		defaultMessage: 'Ga terug',
	},
});

export default () => {
	const { formatMessage: f } = useIntl();
	return (
		<Layout>
			<SEO />
			<div className="flex justify-center align-middle my-64">
				<div className="flex flex-col justify-center items-center p-4">
					<h1 className="font-display text-2xl md:text-3xl text-secondary text-center">
						{f(messages.pageDoesnotExist)}
					</h1>
					<p className="text-lg md:text-xl text-gray-500 text-center">{f(messages.sub)}</p>
					<div className="mt-4">
						<Link to="/" color="primary">
							{f(messages.goBack)}
						</Link>
					</div>
				</div>
			</div>
		</Layout>
	);
};
